





























































































































































import LookUp from '@/components/lookup/Index.vue'
import { authPermissions } from '@/constants'
import { EventBus, ON_SEARCH_PAGE_CREATE, ON_SEARCH_PAGE_DELETE, ON_SEARCH_PAGE_EDIT } from '@/eventbus'
import { clientService } from '@/services'
import { Client } from '@/types/ClientServiceType'
import { SearchConfigField } from '@/types/utilTypes'
import fieldsObj from '@/views/Client.json'
import Vue from 'vue'

function generateForm() {
    return {
        client: {
            clientId: '',
            clientName: '',
            domain: '',
        },
        errors: {} as Record<string,string>,
        upsertDialog: false,
        deleteDialog: false,
        success: false,
        loading: false,
        deleteClient: {} as Client,
        isUpdate: false
    }
}

export default Vue.extend({
    name: 'Clients',
    components: {
        LookUp
    },
    mounted() {
        EventBus.$on(ON_SEARCH_PAGE_CREATE, (pageName:string) => {
            if(pageName === this.options.name) {
                this.onClientCreate()
            }
            
        })
        
        EventBus.$on(ON_SEARCH_PAGE_EDIT, (pageName:string, payload: Client) => {
            if(pageName === this.options.name) { 
                this.onClientUpdate(payload)
            }
            
        })

        EventBus.$on(ON_SEARCH_PAGE_DELETE, (pageName:string, payload: Client) => {
            if(pageName === this.options.name) { 
                this.onClientDelete(payload)
            }
            
        })
    },
    data(){
        return {
            options:{
                crudConfig: {
                    isUpdateable: true,
                    isCreateable: true,
                    isDeleteable: true
                },
                name: 'clients',
                api: '/Client/GetClients',
                exportAPI: {
                    api: '/Client/ExportClients',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                showTotal: true,
                fields: fieldsObj.fields as SearchConfigField[],
                keywordSearchPlaceholder: 'Enter Client Id or Client Name or Domain',
            },
            form: generateForm()
        }
    },
    methods: {
        async onSave() {
            this.form.loading = true
            this.form.errors = {}
            this.form.success = false

            try{
               
                await clientService.upsertClient(this.form.client)
                this.form.success = true
                await this.$store.dispatch('generateNewKey')
            } catch (e) {
                if(this.axios.isAxiosError(e) && e?.response?.status == 400 && e.response.data && e.response.data.errors) {
                    for (const [key, value] of Object.entries(e.response.data.errors)) {
                        this.form.errors[key] = value as string
                    }
                    
                    this.form.errors.General = 'There are errors that require your attention'
                } else {
                    this.form.errors.General = 'We\'re sorry, but we can\'t process your request at this time. Please try again later.'
                }
            }
            this.form.loading = false
        },
        async onDelete() {
            this.form.loading = true
            this.form.errors = {}
            this.form.success = false

            try{ 
                await clientService.deleteClient(this.form.deleteClient)
                this.form.success = true
                await this.$store.dispatch('generateNewKey')
            } catch(e) {
                this.form.errors.General = 'There Are errors that require your attention'
            }
            this.form.loading = false
        },
        onResetForm() {
            this.form = generateForm()
        },
        onClientCreate() {
            this.onResetForm()
            this.form.upsertDialog = true
        },
        onClientUpdate(client: Client) {
            this.onResetForm()
            this.form.client = { ...client}
            this.form.isUpdate = true
            this.form.upsertDialog = true
        },
        onClientDelete(client: Client) {
            this.onResetForm()
            this.form.deleteClient = {...client}
            this.form.deleteDialog = true
        },
    }
})
